import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className={`faq-icon ${isOpen ? 'open' : ''}`}>+</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const HomePage = () => {
  const faqData = [
    {
      question: "What is life insurance?",
      answer: "Life insurance is a contract between an individual and an insurance company, where the insurer pays a stipulated premium, to cover the payee, beneficiaries, next of kin in a situation of death, Hospitalization or Total Permanent Disability benefit to designated beneficiaries."
    },
    {
      question: "Why is life insurance necessary?",
      answer: "Life insurance provides financial protection for your loved ones in the event of your death, covering expenses like final costs, lost income, debts, and children's education."
    },
    {
      question: "How does life insurance work?",
      answer: "Policyholders pay regular premiums, and in return, the insurer provides a lump-sum payment to beneficiaries upon the insured's death."
    },
    
    
    // Add more FAQ items here...
  ];

  return (
    <div className="home-page">
      <nav className="navbar">
        <div className="container">
          <div className="logo">Nketewade Insurance</div>
          <div className="nav-links">
            <a href="#products">Policy Options</a>
            <a href="#contact">Contact</a>
            <Link to="/insurance-form" className="cta-button">Purchase an Insurance Plan</Link>
          </div>
        </div>
      </nav>

      <header className="hero">
        <div className="container">
          <div className="hero-content">
            <h1>Redefining the boundaries of financial security requires pioneers.</h1>
            <p>Nketewade Insurance is currently offering innovative life insurance solutions tailored for the African market. Our groundbreaking policies ensure your family's future is protected.</p>
            <p>If you want to explore new ways of securing your family's financial future, please consider joining our insurance program.</p>
            <div className="cta-buttons">
              <Link to="/insurance-form" className="cta-button primary">Visit Our Insurance Portal</Link>
              <Link to="/policy-options" className="cta-button secondary">Learn about Our Policies</Link>
            </div>
          </div>
        </div>
      </header>

      <section className="faq-section">
        <div className="container">
          <h2>Learn More</h2>
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="footer-content">
            <div className="footer-section">
              <h3>About Us</h3>
              <p>Nketewade Insurance: Pioneering African life insurance solutions.</p>
            </div>
            <div className="footer-section">
              <h3>Contact</h3>
              <p>Email: hello@summerhealth.io</p>
              <p>Phone: +233200645500</p>
            </div>
            <div className="footer-section">
              <h3>Follow Us</h3>
              <div className="social-links">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">Facebook</a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-link">Twitter</a>
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-link">LinkedIn</a>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p>&copy; {new Date().getFullYear()} Summer Health Limited. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;