import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

export const saveUserData = async (userData) => {
  try {
    const docRef = await addDoc(collection(db, "insuranceApplications"), userData);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};