import React from 'react';
import './InsuranceForm.css'; // Make sure to create this CSS file

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>&copy; {currentYear} Summer Health Limited</p>
    </footer>
  );
}

export default Footer;