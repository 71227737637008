import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveUserData } from '../services/databaseService';
import './InsuranceForm.css';

function InsuranceForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
    dateOfPayment: '',
    beneficiaryName: '',
    beneficiaryContact: '',
    coverOption: '',
    paymentMethod: 'credit_card'
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 9) {
      let formattedValue = value;
      if (value.length > 3) {
        formattedValue = value.slice(0, 3) + '-' + value.slice(3);
      }
      if (value.length > 6) {
        formattedValue = formattedValue.slice(0, 7) + '-' + formattedValue.slice(7);
      }
      setFormData({ ...formData, phoneNumber: formattedValue });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docId = await saveUserData(formData);
      setMessage(`Application submitted and payment processed successfully! Reference ID: ${docId}`);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        dateOfPayment: '',
        beneficiaryName: '',
        beneficiaryContact: '',
        coverOption: '',
        paymentMethod: 'credit_card'
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  const handleBack = () => {
    navigate('/'); // This assumes your homepage route is '/'
  };

  return (
    <div className="form-container">
      <div className="form-card">
        <button onClick={handleBack} className="back-btn">← Back to Homepage</button>
        <h1>Nketewade Insurance</h1>
        <p>Please provide the following information to set up your insurance.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              placeholder=" "
            />
            <label htmlFor="firstName" className={formData.firstName ? 'filled' : ''}>First Name</label>
          </div>
          <div className="form-group">
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              placeholder=" "
            />
            <label htmlFor="lastName" className={formData.lastName ? 'filled' : ''}>Last Name</label>
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder=" "
            />
            <label htmlFor="email" className={formData.email ? 'filled' : ''}>Email</label>
          </div>
          <div className="form-group phone-group">
            <span className="country-code">+233</span>
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              required
              placeholder=" "
              maxLength="11"
              pattern="\d{3}-\d{3}-\d{3}"
            />
            <label htmlFor="phoneNumber" className={formData.phoneNumber ? 'filled' : ''}>Phone Number</label>
          </div>
          <div className="form-group">
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
              placeholder=" "
            />
            <label htmlFor="dateOfBirth" className={formData.dateOfBirth ? 'filled' : ''}>Date of Birth</label>
          </div>
          <div className="form-group">
            <input
              type="date"
              name="dateOfPayment"
              id="dateOfPayment"
              value={formData.dateOfPayment}
              onChange={handleChange}
              required
              placeholder=" "
            />
            <label htmlFor="dateOfPayment" className={formData.dateOfPayment ? 'filled' : ''}>Preferred Payment Date</label>
          </div>
          <div className="form-group">
            <input
              type="text"
              name="beneficiaryName"
              id="beneficiaryName"
              value={formData.beneficiaryName}
              onChange={handleChange}
              required
              placeholder=" "
            />
            <label htmlFor="beneficiaryName" className={formData.beneficiaryName ? 'filled' : ''}>Beneficiary Name</label>
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="beneficiaryContact"
              id="beneficiaryContact"
              value={formData.beneficiaryContact}
              onChange={handleChange}
              required
              placeholder=" "
            />
            <label htmlFor="beneficiaryContact" className={formData.beneficiaryContact ? 'filled' : ''}>Beneficiary Contact</label>
          </div>
          <div className="form-group">
            <select
              name="coverOption"
              id="coverOption"
              value={formData.coverOption}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Cover Option</option>
              <option value="2000">GHS 2,000</option>
              <option value="10000">GHS 10,000</option>
              <option value="20000">GHS 20,000</option>
            </select>
            <label htmlFor="coverOption" className={formData.coverOption ? 'filled' : ''}>Select Cover Option</label>
          </div>
          <div className="payment-section">
            <h2>Payment Method</h2>
            <div className="payment-methods">
              <label className={formData.paymentMethod === 'credit_card' ? 'active' : ''}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="credit_card"
                  checked={formData.paymentMethod === 'credit_card'}
                  onChange={handleChange}
                />
                <span className="icon">💳</span> Credit Card
              </label>
              <label className={formData.paymentMethod === 'momo' ? 'active' : ''}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="momo"
                  checked={formData.paymentMethod === 'momo'}
                  onChange={handleChange}
                />
                <span className="icon">📱</span> Mobile Money
              </label>
            </div>
          </div>
          <button type="submit" className="submit-btn">
            Place Order with {formData.paymentMethod === 'credit_card' ? 'Credit Card' : 'Mobile Money'}
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
}

export default InsuranceForm;