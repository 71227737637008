
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Add this line

const firebaseConfig = {
    apiKey: "AIzaSyCoFaVlPvaek4nRcEZu6zTh7ZBdN1-JWLM",
    authDomain: "nketewade-f5a98.firebaseapp.com",
    projectId: "nketewade-f5a98",
    storageBucket: "nketewade-f5a98.appspot.com",
    messagingSenderId: "421666867968",
    appId: "1:421666867968:web:05f9a86e63bc681f44c1e5",
    measurementId: "G-D290SST17H"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };